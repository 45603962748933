@use '@angular/material' as mat;
@import 'src/theme/mixins/mixins';
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin user-details-dialog-theme($theme) {
    $foreground: map-get($theme, foreground);

    .trds-user-details-dialog {
        .action-panel {
            border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
        }
    }
}
