@use '@angular/material' as mat;

@mixin progress-bar-theme() {
    // Extract the palettes you need from the theme definition.

    mat-progress-bar.page-fixed-progress-bar {
        position: fixed;
        left: #{$sidenav};
        right: 0;
        top: $topgap;
        z-index: 101;
    }
}
