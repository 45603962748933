@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin side-bar-buttons-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $accent: map-get($theme, accent);

    .trds-side-bar-buttons {
        .active {
            color: mat.m2-get-color-from-palette($accent);
        }
    }

    .button-wrapper {
        background-color: colors.$bg-surface-3;

        .reset-btn-wrapper {
            .reset-btn {
                color: colors.$chart-dark-blue;
            }

            .reset-btn-divider {
                background-color: colors.$chart-dark-blue;
            }
        }

        .reset-btn-wrapper:hover{
            .reset-btn {
                color: colors.$chart-blue;
            }
            
            .reset-btn-divider {
                background-color: colors.$chart-blue;
            }
        }

        .edit-panel-wrapper{
            .edit-panels-divider {
                background-color: colors.$chart-dark-blue;

                &.edit-mode-on {
                    color: colors.$chart-blue;
                }

                &.edit-mode-off {
                    color: colors.$chart-dark-blue;
                }
            }

            .edit-panels {
                color: colors.$chart-dark-blue;

                &.edit-mode-on {
                    color: colors.$chart-blue;
                }

                &.edit-mode-off {
                    color: colors.$chart-dark-blue;
                }
            }
        }

        .edit-panel-wrapper:hover {
            .edit-panels {
                color: colors.$chart-light-blue;
            }

            .edit-panels-divider {
                background-color: colors.$chart-light-blue;
            }
        }
    }
}
