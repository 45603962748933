@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin case-details-escalations-theme($theme) {
    $foreground: map-get($theme, foreground);

    .trds-case-details-escalations .escalation-event {
        border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
    }
}
