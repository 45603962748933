@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin market-inspection-chart-toolbar-theme($theme, $typography) {
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);
    $foreground: map-get($theme, foreground);

    .chart-toolbar-container {
        .chart-toolbar {
            border: 1px solid colors.$bg-surface-3;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.4);
            background-color: colors.$bg-surface-2;

            .fixed-btn-container {
                .fixed-btn {
                    background-color: colors.$app-background;
                }

                .divider {
                    border-left: 1px solid colors.$bg-surface-3;
                }
            }

            .draw-input-container {
                .divider {
                    border-left: 1px solid colors.$bg-surface-3;
                }
                .draw-type {
                    .bottom-border {
                        border-radius: 0px;
                        border-bottom: solid 2px mat.m2-get-color-from-palette(colors.$solidus-accent, 500);
                    }
                }
            }
        }
    }

    .annotationSave,
    .annotationCancel {
        background-color: transparent !important;
    }

    .annotationCancel {
        background-color: transparent !important;
        margin-left: 8px !important;
    }

    .annotationSave::before {
        content: url('../../../../assets/images/chart-toolbar/done.svg');
    }

    .annotationCancel::before {
        content: url('../../../../assets/images/chart-toolbar/close.svg');
    }

    .stx_annotation {
        border: solid mat.m2-get-color-from-palette(colors.$solidus-primary, 100) 1px;
    }
}
