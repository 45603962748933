@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin client-data-view-dynamic-investigation-theme() {
    trds-client-data-view-dynamic-investigation .title {
        color: colors.$chart-blue;
    }   
    
    .no-data-text{
        color: colors.$snackbar-info-bg
    }
}
