@use '@angular/material' as mat;

@mixin algo-history-dialog-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);

    .trds-algo-history-dialog {
        .dialog-icon-header {
            border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
        }

        .snapshot:not(:last-child) {
            border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
        }
    }
}
