@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin alert-features-agg-table-theme($theme) {
    $background: map-get($theme, background);

    .highlight-row {
        background-color: colors.$bg-surface-3;
    }

    .red-point {
        color: colors.$score-high;
    }

    .avg-color {
        color: colors.$low-priority;
    }
}
