@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin registration-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);

    .trds-registration {
        .registration-container {
            background-color: colors.$bg-surface-3;
            color: mat.m2-get-color-from-palette($foreground, secondary-text);

            h1 {
                color: mat.m2-get-color-from-palette($foreground, text);
            }

        }
    }
}
