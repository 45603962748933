@use '@angular/material' as mat;

@mixin algo-history-threshold-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);

    .trds-algo-history-threshold {
        .accent {
            color: mat.m2-get-color-from-palette($foreground, text);
        }

        .threshold-name {
            color: mat.m2-get-color-from-palette($foreground, secondary-text);
        }
    }
}
