@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin slider-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    trds-slider .slider-borders {
        @include trds-h5();
        
        &::before {
            border-right: 1px solid colors.$disabled-color;
        }
    }
}
