@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin market-inspection-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);

    .trds-market-inspection {
        .sidebar-open .active {
            color: mat.m2-get-color-from-palette($accent);
        }

        .sidebar-content {
            background: colors.$bg-surface-2;
            border-left: 1px solid mat.m2-get-color-from-palette($foreground, divider);
        }

        .chart-events-tools {
            background: colors.$bg-surface-2;
        }

        .legend {
            font-size: mat.m2-font-size($typography, body-2);
        }
    }
}
