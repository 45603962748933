@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin alert-details-elliptic-lens-theme($theme) {
    $foreground: map-get($theme, foreground);

    .trds-alert-details-elliptic-lens {
        .entities-table {
            tr {
                border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
            }

            th {
                color: mat.m2-get-color-from-palette($foreground, secondary-text);
            }
        }
    }
}
