@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin market-inspection-page-theme($theme, $typography) {
    .trds-market-inspection-new {
        .mi-page-content .empty-chart-overload .no-chart-data-message {
            color: colors.$low-priority;
        }

        .smart-zoom-loading-overload {
            background-color: rgb(colors.$bg-surface-2, 0.5);
        }

    }

    .trds-market-inspection-new trds-layout .inner-padding {
        padding: 0;
    }

    .mi-layout {
        .mi-layout-left-side-panel {
            .gridster-wrapper {
                .grid-wrapper {
                    background-color: colors.$bg-surface-2;

                    &.edit-mode {
                        background-color: colors.$bg-surface-4;
                    }
                }

                gridster-item {
                    background: colors.$bg-surface-1;
                }
            }
        }
    }

    .gridster-item-resizable-handler {
        z-index: 300 !important;

        &.handle-s, &.handle-n {
            height: 30px !important;
        }

        &.handle-e, &.handle-w {
            width: 30px !important;
        }
    }

    gridster-item:hover .gridster-item-resizable-handler.handle-se {
        // remove left bottom corner, that appears on hover
        border-color: transparent !important;
    }

    .grid-wrapper.edit-mode {
        gridster-item:hover:after {
            content: " Edit mode activated ";
            @include flex-layout(center, center);
            width: 100%;
            height: 100%;
            background-color: rgb(colors.$panels-hover, 0.8);
            position: absolute;
            top: 0;
            left: 0;
            cursor: move;
            z-index: 290; // according to .gridster-item-resizable-handler z-index
        }
    }
}
