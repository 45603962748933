@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin events-inner-table-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);

    // Define any styles affected by the theme.
    .trds-events-inner-table {
        .controls,
        .table-scroll-container {
            background-color: colors.$app-background;
        }
    }
}
