@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin re-auth-dialog-theme($theme) {
    $foreground: map-get($theme, foreground);

    .trds-re-auth-dialog {
        color: mat.m2-get-color-from-palette($foreground, secondary-text);

        .re-auth-header {
            color: mat.m2-get-color-from-palette($foreground, text);
        }
    }
}
