@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin input-2fa-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .trds-input-2fa {
      .caption {
          @include mat.m2-typography-level($typography, body-2);
      }

      .status-description {
          @include mat.m2-typography-level($typography, body-2);
      }

      .status-error {
          color: mat.m2-get-color-from-palette($warn, default);
      }

      .status-success {
          color: colors.$score-low;
      }
  }
}
