@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin user-details-form-theme($theme, $typography) {
    $foreground: map-get($theme, foreground);

    .trds-user-details-form {
        .permission,
        .team-description,
        .team-hint-message {
            @include mat.m2-typography-level($typography, body-2);
            color: mat.m2-get-color-from-palette($foreground, secondary-text);
        }

        .checkbox-list {
            background: colors.$bg-surface-2;

            mat-checkbox  {
                text-transform: capitalize;
            }
        }
    }
}
