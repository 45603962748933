@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin client-details-page-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);

    .trds-client-details {
        .page-header {
            border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
        }
    }
}
