@use '@angular/material' as mat;

@mixin app-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);

    * {
        @include scrollbar-theme($theme);
    }
}
