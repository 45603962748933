@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin advanced-multi-select-filter-theme($theme, $typography) {
    $foreground: map-get($theme, foreground);

    .content-body-wrapper .clear-all {
        @include trds-h5();
    }
}
