@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin alert-details-elliptic-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);

    .trds-alert-details-elliptic {
        .entities-table {
            tr {
                border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
            }

            th {
                color: mat.m2-get-color-from-palette($foreground, secondary-text);
            }
        }

        .external-link:hover {
            color: mat.m2-get-color-from-palette($accent, 100);
        }
    }
}
