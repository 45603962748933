@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin quick-actions-theme($theme, $typography) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .trds-quick-actions {
        .items,
        .sub-items {
            background-color: colors.$bg-surface-3;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }

        .item,
        .label-item {
            @include mat.m2-typography-level($typography, body-1);
            background-color: colors.$bg-surface-3;
            border: none;
            outline: none;
        }

        .item {
            color: mat.m2-get-color-from-palette(colors.$solidus-primary, 100);
            line-height: 1.5rem;

            .icon {
                color: mat.m2-get-color-from-palette(colors.$solidus-primary, 500);
            }

            &:hover,
            &:active,
            &.highlight {
                background-color: colors.$bg-surface-4;
            }
        }

        .label-item {
            color: mat.m2-get-color-from-palette($foreground, secondary-text);
            border-top: 1px solid colors.$bg-surface-4;
            line-height: 2.5rem;
        }
    }
}
