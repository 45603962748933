@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin spinner-button-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);

    .trds-spinner-button [mat-flat-button] .trds-spinner circle {
        stroke: colors.$bg-surface-3;
    }

    .spinner-prefix:focus-visible {
        outline: 2px solid mat.m2-get-color-from-palette($foreground, base);
    }
}
