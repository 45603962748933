@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin row-score-color($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    &.low {
        @include row-prefix-bar();

        &:before {
            background-color: colors.$score-low;
        }
    }

    &.medium {
        @include row-prefix-bar();

        &:before {
            background-color: colors.$score-medium;
        }
    }

    &.high {
        @include row-prefix-bar();

        &:before {
            background-color: colors.$score-high;
        }
    }
}

@mixin row-prefix-bar() {
    position: relative;
    &:before {
        content: '';
        width: 3px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
}
