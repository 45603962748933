@use '@angular/material' as mat;

@mixin manual-ca-attachment-theme($theme) {
    $foreground: map-get($theme, foreground);

    .trds-manual-ca-attachments {
        .no-attach {
            border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
        }
    }
}
