@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin hmac-secret-key-settings-theme($theme, $typography) {
    $foreground: map-get($theme, foreground);

    trds-hmac-secret-key-settings {
        .date-display .label {
            font-size: mat.m2-font-size($typography, body-2);
            color: mat.m2-get-color-from-palette($foreground, secondary-text);
        }
    }
}
