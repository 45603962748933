@mixin fullsize-element-styles() {
    @include flex-layout($direction: column);
    flex: 1;
    overflow: hidden;
    max-height: 100%;
}

@mixin flex-layout($valign: null, $halign: null, $direction: null, $wrap: null, $grow: null, $basis: null) {
    display: flex;
    align-items: $valign;
    justify-content: $halign;
    flex-direction: $direction;
    flex-wrap: $wrap;

    & > * {
        flex-grow: $grow;
        flex-basis: $basis;
    }
}

// used for placing child elements at one hierarchy level
@mixin grid-layout($template-columns: null, $column-gap: null, $row-gap: null, $grid-gap: null, $template-rows: null) {
    display: grid;
    grid-template-columns: $template-columns;
    column-gap: $column-gap;
    row-gap: $row-gap;
    grid-template-rows: $template-rows;
    gap: $grid-gap;
}

// used for Details grid table. Plases .rows one under the other and .keys and .values in one line inside the .row
@mixin grid-rows($template-columns: null, $min-height: null, $grid-gap: null, $margin-bottom: $double-gap) {
    display: grid;
    margin-bottom: $margin-bottom;

    .row {
        display: grid;
        grid-template-columns: $template-columns;
        min-height: $min-height;
        gap: $grid-gap;
        align-content: center;
        align-items: baseline;
    }

    .key,
    .value {
        padding: $half-gap 0;
    }

    &:not(.no-border-grid) .value {
        overflow: hidden;
    }
}

@mixin ellipsis() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin text-with-icon-cell() {
    @include flex-layout(flex-start);
    overflow: hidden;

    .mat-icon {
        @include small-icon-styles();
        max-width: $small-icon-size;
        margin-left: $half-gap;
        padding: 0;
        flex: 1;
    }
}
