@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin matches-check-theme() {
    .trds-matches-check {
        .inputs-table .match-td {
            background-color: colors.$app-background;
        }
    }
}
