@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin chartiq-marker-component-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);

    // Define any styles affected by the theme.

    .stx-marker-expand {
        @include mat.elevation(2);
        @include mat.m2-typography-level($typography, caption);
        background: mat.m2-get-color-from-palette($primary);

        div:nth-child(odd) {
            color:  mat.m2-get-color-from-palette($foreground, text);
        }

        div:nth-child(even) {
            color:  mat.m2-get-color-from-palette($foreground, secondary-text);
        }
    }
}
