@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin solibot-chat-theme($theme) {
    $foreground: map-get($theme, foreground);

    $chat-background-color: colors.$bg-surface-1;
    $chat-border-radius: 20px;
    $chat-text-color: #fff;

    $chat-bot-message-border-color: #FFCD26;
    $chat-user-message-border-color: colors.$chart-blue;

    $chat-textarea-background-color: colors.$bg-surface-3;
    $chat-textarea-border-radius: 20px;

    $chat-send-button-color: #CAD5DB;

    .trds-solibot-btn {
        .chat-button {
            background-color: $chat-text-color;

            &:hover {
                background-color: mat.m2-get-color-from-palette(colors.$solidus-accent, 100);
            }
        }
    }

    .trds-solibot-chat {
        .chat-container {
            border-radius: $chat-border-radius;
            background: $chat-background-color;
            border: 2px solid #51A9FF;
            box-shadow: 0 7px 11px 4px rgba(0, 0, 0, 0.46);
        }

        .message {
            background-color: $chat-background-color;
            color: $chat-text-color;

            &.bot,
            &.loading {
                border: 1px solid $chat-bot-message-border-color;
            }

            &.user {
                border: 1px solid $chat-user-message-border-color;
            }

            &.loading:after {
                color: $chat-text-color;
            }
        }

        .chat-actions {
            .chat-form {
                border-radius: $chat-textarea-border-radius;
                background-color: $chat-textarea-background-color;
            }

            .chat-input {
                color: $chat-text-color;

                &::placeholder {
                    color: $chat-text-color;
                }
            }

            .send-button {
                color: $chat-send-button-color;
            }
        }

        .info-text {
            text-align: center;
            font-size: 10px;
            color: mat.m2-get-color-from-palette($foreground, secondary-text);
        }
    }
}
