@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/mixins/mixins.scss';

@mixin alert-details-generic($foreground, $background, $typography, $theme) {
    .content-container {
        background: colors.$app-background;
    }

    .analysis-details mat-card {
        @include mat.elevation(8);
    }
}
