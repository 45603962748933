@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin model-test-algo-time-range-theme() {
    .trds-model-test-algo-time-range {
        .container {
            border: 1px solid colors.$app-hyperlink;
            background: colors.$bg-surface-2;
        }
    }
}
