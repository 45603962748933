@use '@angular/material' as mat;

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
// $input line-height must be unit-less fraction of the font-size:
// https://github.com/angular/components/issues/14312#issuecomment-442512272
$tardis-typography: mat.m2-define-typography-config(
    $font-family: 'Nunito Sans',
    $headline-4: mat.m2-define-typography-level(4.43rem, 4.3rem, 700, 'Source Serif Pro', 0.75px),
    $headline-5: mat.m2-define-typography-level(1.43rem, 2rem, 400, 'Source Serif Pro', 0.5px),
    $headline-6: mat.m2-define-typography-level(1.43rem, 2rem, 400, 'Barlow', 0.5px),
    $subtitle-1: mat.m2-define-typography-level(1.25rem, 1.5rem, 400, 'Barlow', $letter-spacing: 0.75px),
    $subtitle-2: mat.m2-define-typography-level(1rem, 1.14rem, 600, $letter-spacing: 0.75px),
    $body-1: mat.m2-define-typography-level(1rem, 1.43rem, 400, $letter-spacing: 0.14px),
    $body-2: mat.m2-define-typography-level(0.86rem, 1.14rem, 400, $letter-spacing: 0.25px),
    $caption: mat.m2-define-typography-level(1rem, 1.14rem, 400, $letter-spacing: 0.25px),
    $button: mat.m2-define-typography-level(1rem, 2.45rem, 600, $letter-spacing: 0.14px),
); //h1 - $headline-5

$trds-label: 500 1rem/1.43rem 'Barlow'; // derived from body-1;

$trds-tooltip: 400 0.86rem/1.43rem 'Nunito Sans'; // derived from body-1;

$chart-tooltip: 0.86rem/1.5rem 'Nunito Sans'; // derived from body-1;

$trds-h5: 600 0.86rem/1.14rem 'Nunito Sans';
$trds-h6: 600 0.71rem/1.14rem 'Nunito Sans';

@mixin trds-tooltip() {
    font: $trds-tooltip;
    letter-spacing: 0.75px;
}

@mixin chart-tooltip() {
    font: $chart-tooltip;
    letter-spacing: 0.216px;
}

@mixin trds-h5() {
    font: $trds-h5;
    letter-spacing: 0.75px;
    margin: 0; // default margins clearing
}

@mixin trds-h6() {
    font: $trds-h6;
    letter-spacing: 0.75px;
    margin: 0; // default margins clearing
}

// body.trds-typography is needed to overwrite meterial styles
body.trds-typography {
    h5,
    .trds-h5 {
        @include trds-h5();
    }

    h6,
    .trds-h6 {
        @include trds-h6();
    }
}

.mat-subtitle-1 {
    text-transform: uppercase;
}

.mat-typography,
body.mat-typography {
    @include mat.m2-typography-level($tardis-typography, body-1);
}