@use 'src/theme/solidus-black/colors' as colors;
@use '@angular/material' as mat;

@mixin case-time-in-statuses-dialog($theme) {
    $foreground: map-get($theme, foreground);

    .trds-case-time-in-statuses-dialog {
        .case-life-cycle {
            &-onSchedule {
                color: colors.$score-low;
            }
            &-behindSchedule {
                color: colors.$score-medium;
            }
            &-offSchedule {
                color: colors.$score-high;
            }
        }

        .time-in-status {
            color: mat.m2-get-color-from-palette($foreground, text);
        }

        .secondary-text {
            color: mat.m2-get-color-from-palette($foreground, secondary-text);
        }
    }
}
