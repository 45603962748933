@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin legend-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.

    trds-legend .legend {
        background-color: rgba(colors.$bg-surface-1, 0.7);
        font-size: mat.m2-font-size($typography, body-2);
    }
}
