@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin base-cases-theme() {
    .cases-layout {
        .inner-padding,
        .mat-drawer-container {
            overflow: visible;
        }

        mat-drawer.bulk-update-sidebar {
            top: (-$gap);
            right: (-$double-gap);
        }
    }
}
