@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin alert-details-header-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);

    .trds-alert-details-header .header-row {
        background: colors.$app-background;
        border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);

        .key {
            color: mat.m2-get-color-from-palette($foreground, secondary-text);
        }

        .header-item {
            @include score-color($theme, $typography);

            &.score {
                font-size: inherit;
            }
        }
    }
}
