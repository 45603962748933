@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin score-color-theme() {
    // Extract the palettes you need from the theme definition.

    .score {
        &.low {
            color: colors.$score-low;
        }

        &.medium {
            color: colors.$score-medium;
        }

        &.high {
            color: colors.$score-high;
        }
    }
}
