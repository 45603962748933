@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin breadcrumbs-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);

    .trds-breadcrumbs {
        // Define any styles affected by the theme.
        .mat-toolbar {
            border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
            background-color: colors.$app-background;

            .breadcrumbs {
                .main {
                    @include mat.m2-typography-level($typography, headline-5);
                    font-size: $main-title;

                    &.hovered:hover {
                        color: mat.m2-get-color-from-palette($accent, 100);   
                    }

                    @media screen and (max-width: calc(#{$window-s} - 1px)) {
                        font-size: mat.m2-font-size($typography, subtitle-1);
                    }
                }

                h3 {
                    @include mat.m2-typography-level($typography, body-1);
                    color: mat.m2-get-color-from-palette($primary, 500);

                    &:hover {
                        color: mat.m2-get-color-from-palette($accent, 100);   
                    }
                }

                .sub::after {
                    color: mat.m2-get-color-from-palette($foreground, secondary-text);
                    @include mat.m2-typography-level($typography, body-1);
                }
            }
        }
    }
}
