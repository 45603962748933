@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';
@import 'src/theme/mixins/mixins.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin expansion-panel-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    // Define any styles affected by the theme.
    mat-expansion-panel.mat-expansion-panel {
        background-color: colors.$bg-surface-2;
        // there are two types of mat-expansion-panel
        // the most commonly used type has margin-bottom
        margin-bottom: $three-half-gaps; // override material default margin-bottom: 0; Make sense while type-1 is used more frequently than ".panel-bordered"

        &.panel-bordered {
            border-radius: 0; // override material radius
            @include mat.elevation(0); // override material box-shadow
            border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
            margin-bottom: 0; // Returns material default margin-bottom: 0; Make sense while type-1 is used more frequently than ".panel-bordered"
        }

        h3 {
            color: mat.m2-get-color-from-palette($foreground, secondary-text);
            margin-bottom: 0;

            &:not(.no-capitalize) {
                text-transform: capitalize;
            }
        }

        .panel-content {
            background-color: colors.$app-background;
            padding: 0 $gap;
            overflow: hidden;
            @include mat.m2-typography-level($typography, body-1);

            h5 {
                color: colors.$disabled-color;
                margin-bottom: $gap;
            }

            &.full-padding {
                padding: $gap;
            }
        }

        .panel-actions {
            @include flex-layout(center, space-between);
            margin: $gap 0 $quarter-gap;
        }

        @media (max-width: calc( #{$window-s} - 1px)) {
            .mat-expansion-panel-header {
                padding: $quarter-gap $gap;
                min-height: 3rem;
                height: auto;
            }

            .panel-content {
                margin: 0 (-$gap);
            }
        }
        @media (max-width: calc( #{$window-xxs} - 1px)) {
            .panel-content {
                padding: 0 $three-quarter-gaps;
            }
        }
    }
}
