@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin risk-mapping-dialog-theme($theme, $typography) {
    $foreground: map-get($theme, foreground);

    .trds-risk-mapping-dialog {
        .tab-header h2{
            color: mat.m2-get-color-from-palette($foreground, text);
        }

        .header-row {
            color: mat.m2-get-color-from-palette($foreground, secondary-text);
        }
    }
}
