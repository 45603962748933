@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin layer-manager-theme($theme, $typography) {
    .trds-layer-manager {
        font-size: mat.m2-font-size($typography, body-2);

        .participants {
            background-color: rgba(colors.$bg-surface-1, 0.85);
            @include mat.elevation(5);
        }
    }
}
