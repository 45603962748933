@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

@mixin application-details-page-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .trds-application-details {
        .page-header {
            border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
        }

        .applicant-tabs {
            background: colors.$bg-surface-1;
        }

        .input-name {
            color: mat.m2-get-color-from-palette($foreground, secondary-text);
        }

        .error {
            color: mat.m2-get-color-from-palette($warn);
        }

        .history {
            li:not(:last-child) {
                border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
            }

            .origin {
                color: mat.m2-get-color-from-palette($foreground, secondary-text);
            }
        }

        .panel-name-status {
            .status {
                &.not_started {
                    color: mat.m2-get-color-from-palette($foreground, disabled);
                }

                &.pending {
                    color: mat.m2-get-color-from-palette($foreground, text);
                }

                &.running {
                    color: colors.$side-buy;
                }

                &.passed {
                    color: colors.$low-priority;
                }

                &.failed {
                    color: colors.$score-medium;
                }

                &.set_check_status {
                    color: colors.$high-priority;
                }

                &.no_check {
                    color: colors.$medium-priority;
                }
            }
        }

        .email-row {
            border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);

            .key {
                color: mat.m2-get-color-from-palette($foreground, secondary-text);
            }
        } 
    }
}
