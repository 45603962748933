@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin description-theme($theme) {
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);

    trds-description.description-theme {
        div {
            hr {
                color: mat.m2-get-color-from-palette($foreground, disabled-button);
            }

            span {
                color: mat.m2-get-color-from-palette($foreground, secondary-text);
            }
        }

        a:hover {
            color: mat.m2-get-color-from-palette($accent, 100);
        }

        hr {
            border-color: mat.m2-get-color-from-palette($foreground, divider);
            opacity: 0.5;
        }

        p.divider {
            color: mat.m2-get-color-from-palette($foreground, divider);
        }

        h2 {
            margin-bottom: $gap;
        }
    }
}
