@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin chip-theme($theme) {
    // Extract the palettes you need from the theme definition.

    // needs to be checked in material 17 or higher

    trds-chip {
        .button-group .mdc-icon-button__ripple,
        .button-group .mat-mdc-button-ripple{ 
            display: none!important;
        }

        [mat-icon-button]:disabled {
            color: colors.$disabled-bg;
        }

    }
}
