@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin dialog-error-theme($theme) {
    $warn: map-get($theme, warn);

    .trds-dialog-error {
        background: mat.m2-get-color-from-palette($warn, A400);
        color: colors.$app-background;
    }
}
