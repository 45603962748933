@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin file-upload-data-table-theme($theme) {
    $foreground: map-get($theme, foreground);

    .last-update-title {
        color: mat.m2-get-color-from-palette($foreground, secondary-text);
    }
}
