@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';
@import 'src/theme/mixins/mixins';
@import 'src/theme/solidus-black/typography';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin ag-grid-table-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);


    .ag-theme-alpine-dark {
        --ag-grid-size: 5.5px; /* very compact */
        --ag-header-height: 32px;
        --ag-cell-horizontal-padding: 7px;
    }

    .ag-grid-table {
        .ag-theme-alpine-dark {
            @include mat.m2-typography-level($typography, body-1);
            --ag-icon-font-family: agGridMaterial;
            --ag-selected-tab-underline-color: #ffcd26;
            --ag-alpine-active-color: #ffcd26;
            --ag-background-color: #303030;
            --ag-widget-vertical-spacing: 1rem;
            --ag-widget-container-horizontal-padding: 1.5rem;
            --ag-row-hover-color: #535353;
            --ag-input-focus-border-color: #ffe282;
            --ag-input-focus-box-shadow: none;
            --ag-range-selection-highlight-color: rgba(255, 205, 38, 0.2);
            --ag-value-change-value-highlight-background-color: rgba(255, 205, 38, 0.2);

            .ag-sort-indicator-icon .ag-icon {
                color: mat.m2-get-color-from-palette($accent, 500);
            }

            .ag-header-cell-filtered .ag-filter-icon {
                display: flex;
                color: mat.m2-get-color-from-palette($accent, 500);
            }

            .ag-header-cell:not(.ag-header-cell-filtered) .ag-filter-icon {
                display: none;
            }

            .ag-paging-description {
                display: none;
            }

            .ag-overlay-loading-wrapper {
                background-color: colors.$bg-surface-1;
                opacity: 0.6;
            }

            &.ag-dnd-ghost {
                background-color: colors.$bg-surface-4;
                border: none;

                .ag-dnd-ghost-label {
                    @include mat.m2-typography-level($typography, body-1);
                }
            }
        }

        .ag-header,
        .ag-root-wrapper {
            background-color: colors.$bg-surface-1;

            .ag-header-row {
                @include mat.m2-typography-level($typography, body-1);
                color: mat.m2-get-color-from-palette($primary, 500);
            }
        }

        .ag-table-header {
            background-color: colors.$bg-surface-1;
            border-left: 1px solid colors.$bg-surface-5;
            border-right: 1px solid colors.$bg-surface-5;
            border-top: 1px solid colors.$bg-surface-5;

            label {
                color: mat.m2-get-color-from-palette($foreground, secondary-text);
            }

            .export-csv-btn {
                color: mat.m2-get-color-from-palette(map-get($tardis-theme, primary), 500);
            }
        }

        .ag-root-wrapper,
        .ag-rtl .ag-side-bar-left,
        .ag-ltr .ag-side-bar-right,
        .ag-header,
        .ag-paging-panel {
            border-color: colors.$bg-surface-5;
        }

        .ag-body-viewport.ag-layout-auto-height {
            min-height: 270px;

            .ag-center-cols-viewport {
                min-height: 270px;
            }
        }

        .ag-filter {
            .ag-picker-field-wrapper {

                &:focus,
                &:hover,
                &[aria-expanded="true"] {
                    border-color: mat.m2-get-color-from-palette(colors.$solidus-accent, 100);
                }


                &:hover {
                    cursor: pointer;
                }

                .ag-icon-small-down:before {
                    color: mat.m2-get-color-from-palette(colors.$solidus-primary, 500);
                }
            }
        }

        .ag-row,
        .ag-row-odd {
            background-color: colors.$bg-surface-1;
        }

        .ag-row-hover:not(.ag-full-width-row)::before,
        .ag-row-hover.ag-full-width-row.ag-row-group::before {
            background-color: colors.$row-hover;
        }

        .ag-row.clickable {
            cursor: pointer;
        }

        .ag-cell.side-highlight {
            padding-left: $gap;

            &:before {
                content: '';
                width: 3px;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
            }

            &.status-low:before {
                background: colors.$score-low;
            }

            &.status-medium:before {
                background: colors.$score-medium;
            }

            &.status-high:before {
                background: colors.$score-high;
            }

            &.status-completed:before {
                background: colors.$score-low;
            }

            &.status-failed:before {
                background: colors.$score-high;
            }

            &.status-pending:before {
                background: colors.$low-priority;
            }

            &.status-running:before {
                background: colors.$chart-blue;
            }

            &.status-partial_success:before {
                background: colors.$side-buy;
            }
        }

        .ag-cell.side {
            font-weight: 600;

            &.SELL {
                color: colors.$side-sell;
            }

            &.BUY {
                color: colors.$side-buy;
            }
        }

        .ag-cell.link-cell:hover {
            color: mat.m2-get-color-from-palette($accent, 100);
            text-decoration: underline;
            cursor: pointer;
        }

        .ag-cell.error-color {
            color: colors.$score-high;
        }

        .ag-cell.align-center {
            @include flex-layout(center, center);
        }

        .ag-cell.ag-cell-focus.no-focus {
            border-color: transparent;
        }

        .ag-pinned-left-header,
        .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
            border-right: none;
        }
        .ag-has-focus .ag-cell.ag-cell-last-left-pinned.ag-cell-focus {
            border-right: 1px solid mat.m2-get-color-from-palette($accent, 500);
        }

        .ag-side-bar-right .ag-tool-panel-wrapper {
            background-color: colors.$bg-surface-3;
            border-right: none;
        }
        .ag-column-panel-column-select {
            border: none;
        }

        .ag-ltr .ag-column-select-column-drag-handle {
            transform: rotate(90deg);
        }

        .ag-theme-alpine-dark .ag-paging-row-summary-panel-number {
            @include mat.m2-typography-level($typography, body-1);
        }

        .ag-paging-button .ag-icon {
            font-size: mat.m2-font-size($typography, subtitle-1);
        }

        .ag-menu {
            border: none;
            background-color: colors.$bg-surface-3;
            @include mat.m2-typography-level($typography, body-1);
        }

        .ag-tab .ag-icon {
            color: mat.m2-get-color-from-palette($primary, 500);
        }

        .ag-tab.ag-tab-selected .ag-icon {
            color: mat.m2-get-color-from-palette($accent, 500);
        }

        .ag-menu-header {
            background-color: colors.$bg-surface-3;
            border-color: colors.$bg-surface-5;
        }

        .ag-menu-separator-part::after {
            border-color: colors.$bg-surface-5;
        }

        .ag-menu-option {
            .ag-icon.ag-icon-small-right {
                color: mat.m2-get-color-from-palette(colors.$solidus-primary, 500);
                font-size: $small-icon-size;
            }

            .ag-menu-option-icon {
                mat-icon {
                    width: 1.143rem;
                    height: 1.143rem;
                    font-size: 1.357rem;
                    line-height: 1.143rem;
                    display: block;
                }
            }
        }

        .ag-menu-option-active {
            background-color: colors.$row-hover;
            cursor: pointer;
        }

        .ag-menu-option.label {
            pointer-events: none;
            touch-action: none;
            color: mat.m2-get-color-from-palette($foreground, secondary-text);
            border-top: 1px solid colors.$bg-surface-4;
            line-height: 2.5rem;
        }

        .ag-header-cell.ag-header-cell-moving {
            background-color: colors.$bg-surface-5;
        }

        .ag-icon.ag-icon-grip.ag-drag-handle.ag-column-select-column-drag-handle {
            background: transparent url('/assets/images/icons/drag_indicator.svg') center/contain no-repeat;
            color: transparent;
            transform: rotate(0);
        }

        .ag-row-loading {
            display: none;
        }

        .ag-labeled.ag-checkbox {
            margin-left: $two-third-gap;
        }
    }

    .no-results {
        @include mat.m2-typography-level($typography, body-1);
    }

    .ag-checkbox-input-wrapper {
        background: none;
    }

    .cell-tooltip-value {
        @include trds-tooltip();
        @include mat.elevation(4);
        color: colors.$app-background;
        background-color: mat.m2-get-color-from-palette($primary, 500);
        padding: $half-gap;
        border-radius: 2px;

        b {
            font-family: mat.m2-font-family($typography, headline-6);
        }
    }
}
