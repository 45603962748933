@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/solidus-black/typography';

@mixin simple-details-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .inputs-table {
        &:after {
            border-bottom: 1px solid colors.$bg-surface-3;
        }

        .td:not(.no-border),
        .wide:not(.no-border) {
            border-bottom: 1px solid colors.$bg-surface-3;
        }

        .wide:not(:first-child):before {
            background: colors.$bg-surface-3;
        }

        .input-name {
            @include trds-h5();
        }
    }
}
