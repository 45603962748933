@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin cases-bulk-update-drawer-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);

    .trds-cases-bulk-update-drawer {
        .header {
            border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
        }

        .mat-expansion-panel {
            .panel-content {
                padding: $gap;
            }

            .mat-expansion-panel-header[aria-disabled=true] h3 {
                color: var(--mat-expansion-header-disabled-state-text-color);
            }
        }
    }
}
