@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/components/scrollbar.theme';
@import 'src/theme/variables';
@import 'src/theme/mixins/mixins';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin checks-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .applicant-tabs {
        .check-section-title {
            color: mat.m2-get-color-from-palette($foreground, secondary-text);
        }

        .check-small-icon {
            @include icon-size($small-icon-font);
            margin-right: 0.5rem;
        }
    }

    .success {
        color: colors.$score-low;
    }

    trds-compare-matches-dialog .paginator {
        @include mat.m2-typography-level($typography, body-2);
    }

    .small-label {
        @include mat.m2-typography-level($typography, body-2);
        color: mat.m2-get-color-from-palette($foreground, secondary-text);
    }
}
