@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin forgot-password-theme($theme) {
    $foreground: map-get($theme, foreground);

    .spinner-prefix:focus-visible, .return-btn:focus-visible {
        outline: 2px solid mat.m2-get-color-from-palette($foreground, base);
    }
}
