@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin multiple-values-tooltip-theme($theme) {
    $foreground: map-get($theme, foreground);

    .trds-multiple-values-tooltip {
        .hidden-count {
            color: colors.$tooltip-bd-color;
        }

        .action {
            color: colors.$tooltip-bd-color;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
