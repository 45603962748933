@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

@mixin run-algo-dialog-theme($theme) {
    $accent: map-get($theme, accent);

    $foreground: map-get($theme, foreground);

    .trds-run-algo-dialog {
        .attention {
            background-color: rgba(colors.$bg-surface-2, 0.35);
            color: mat.m2-get-color-from-palette($accent, 100);
            padding: $gap;
            margin-bottom: $gap;
        }
    }
}
