@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

// Define a custom mixin that takes in the current theme
@mixin sidenav-theme() {
    // needs save depth level of 2 because of requirements of product
    mat-sidenav-container.sidenav-paddding,
    mat-drawer-container.mat-drawer-container {
        background-color: colors.$app-background;

        .actions-bar button {
            width: $btn-md-size;
            height: $btn-md-size;
            line-height: $btn-md-size;
            margin-bottom: $gap;
            padding: 2px 5px;

        }

        trds-sidenav .mdc-icon-button__ripple,
        trds-sidenav .mat-mdc-button-ripple,
        trds-sidenav .mdc-button__ripple{ 
            display: none!important;
        }
    }
}
