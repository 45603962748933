@use '@angular/material' as mat;

@mixin manual-ca-check-theme($theme, $typography) {
    $accent: map-get($theme, accent);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .trds-manual-ca {
        .section-title {
            color: mat.m2-get-color-from-palette($foreground, secondary-text);
        }

        .warning {
            @include mat.m2-typography-level($typography, body-2);
            color: mat.m2-get-color-from-palette($accent, 100);
        }
    }
}
