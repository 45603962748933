@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

@mixin client-link-multiple-view-theme() {
    .trds-client-link-multiple-view {
        .additional-client-ids,
        .additional-client-ids:hover {
            background-color: colors.$bg-surface-3;
            color: mat.m2-get-color-from-palette(colors.$solidus-primary, 100);
        }

        .additional-client-ids {
            border-radius: $gap;

            .mat-button-wrapper {
                padding: 0 $half-gap;
            }
        }
    }
}
