@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin button-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);
    $foreground: map-get($theme, foreground);

    [mat-raised-button]:not(:disabled) {
        background-color: colors.$bg-surface-3;
    }

    [mat-mini-fab].mat-primary:not(:disabled):hover,
    [mat-mini-fab].mat-accent:not(:disabled):hover,
    [mat-flat-button]:not(:disabled):hover,
    [mat-raised-button]:not(:disabled):hover {
        background-color: mat.m2-get-color-from-palette($accent, 100);
    }

    [mat-raised-button]:not(:disabled):hover,
    [mat-flat-button]:not(:disabled):hover {
        color: colors.$light-highlight-text;
    }

    [mat-stroked-button].mat-primary:not(:disabled) {
        border: 1px solid mat.m2-get-color-from-palette($primary, 500);
    }

    [mat-stroked-button].mat-primary:not(:disabled):hover,
    [mat-button].mat-primary:not(:disabled):hover {
        color: mat.m2-get-color-from-palette($accent, 100);
    }

    [mat-mini-fab].basic-fab-btn:not(:disabled) {
        background-color: colors.$bg-surface-3;
        color: mat.m2-get-color-from-palette($foreground, base);

        &:hover {
            background-color: mat.m2-get-color-from-palette($accent, 100);
            color: colors.$light-highlight-text;
        }
    }

    [mat-button].mat-primary:disabled,
    [mat-icon-button].mat-primary:disabled  {
        color: colors.$disabled-bg;
    }

    [mat-mini-fab].mat-primary:disabled,
    [mat-flat-button].mat-primary:disabled,
    [mat-mini-fab].mat-accent:disabled
    [mat-flat-button].mat-accent:disabled {
        color: colors.$disabled-color;
        background-color: colors.$disabled-bg;
    }

    [mat-icon-button].mat-primary:not(:disabled):hover {
        color: mat.m2-get-color-from-palette($accent, 100);
    }

    .mat-button-toggle-appearance-standard {
        background: none;
        color: mat.m2-get-color-from-palette($primary, 500);
    }

    [mat-flat-button].mat-primary:not(:disabled):focus-visible {
        outline: 3px solid rgba(mat.m2-get-color-from-palette($foreground, base), 0.4);
    }

    button > span {
        vertical-align: middle;
        @include flex-layout(center);
    }

    button:not([role="switch"]):not([role="option"]) span {
        max-width: 100%;
    }

    button[mat-button],
    button[mat-stroked-button],
    button[mat-flat-button],
    button[mat-raised-button] {
         mat-icon.mat-icon {
            font-size: $small-icon-font;
            height: $small-icon-size;
            width: $small-icon-size;
            margin: 0;
        }

        mat-icon.mat-icon ~ *:not(:empty) {
            padding-left: $one-third-gap;
        }

        mat-icon.mat-icon + mat-icon.mat-icon {
            padding-left: 0;
        }

        &.go-to-btn mat-icon.mat-icon,
        mat-icon.mat-icon:last-child {
            order: 1;
            margin-right: 0;
            margin-left: $one-third-gap;
        }

        &.go-to-btn {
            text-transform: capitalize;

            mat-icon.mat-icon {
                transform: rotate(-45deg);
            }
        }
    }

    button.mat-mdc-icon-button[mat-icon-button],
    a.mat-mdc-icon-button[mat-icon-button],
    button[mat-mini-fab],
    a[mat-mini-fab] {
        width: $mat-icon-button-length;
        height: $mat-icon-button-length;
        overflow: hidden;
        padding: 4px;
    }

    @media (max-width: calc($window-xxs - 1px)) {
        button[mat-flat-button],
        button[mat-stroked-button] {
            padding: 0 $three-quarter-gaps;
        }
    }

    button[mat-icon-button] mat-spinner svg {
        width: $small-icon-size;
        height: $small-icon-size;
    }
}

mat-icon.mat-icon {
    font-size: $small-icon-font;
    line-height: $small-icon-size;
    width: $small-icon-size;
    height: $small-icon-size;

    svg {
        width: 100%;
        height: 100%;
    }
}
