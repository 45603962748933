@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';
@import 'src/theme/mixins/mixins';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin ag-grid-traders-trade-history-table-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .traders-trade-container{
        background-color: colors.$bg-surface-1;

        .traders-trade-history-header{
            color: mat.m2-get-color-from-palette($foreground, secondary-text);
        }

        .ag-root-wrapper {
            border:none !important;
            --ag-borders: none;
            //border-color: red !important;
        }
    }

    .ag-grid-traders-trade-table {
        // @include ag-grid-table-theme($theme, $typography);
        .ag-header,
        .ag-root-wrapper {
            background-color: colors.$bg-surface-1;

            &.ag-ltr,
            &.ag-rtl {
                border: none;
            }

            .ag-header-row {
                @include mat.m2-typography-level($typography, body-1);
                color: mat.m2-get-color-from-palette($primary, 500);
            }
        }

        .ag-root-wrapper,
        .ag-rtl .ag-side-bar-left,
        .ag-ltr .ag-side-bar-right,
        .ag-header,
        .ag-paging-panel {
            border-color: colors.$bg-surface-3;
        }

        .ag-row {
            border-bottom: 2px solid colors.$bg-surface-1;
        }

        .ag-row,
        .ag-row-odd {
            background-color: colors.$bg-surface-2;
        }

        .ag-row-focus {
            background-color: colors.$bg-surface-3;
        }

        .ag-row-hover:not(.ag-full-width-row)::before,
        .ag-row-hover.ag-full-width-row.ag-row-group::before {
            background-color: colors.$bg-surface-3;
        }

        .ag-pinned-left-header,
        .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
            border-right: none;
        }

        .ag-has-focus .ag-cell.ag-cell-last-left-pinned.ag-cell-focus {
            border-right: 1px solid mat.m2-get-color-from-palette($accent, 500);
        }

        .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
        .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
            border: 1px solid mat.m2-get-color-from-palette($accent, 500);
        }

        .ag-side-bar-right .ag-tool-panel-wrapper {
            background-color: colors.$bg-surface-3;
            border-right: none;
        }

        .ag-column-panel-column-select {
            border: none;
        }

        .ag-ltr .ag-column-select-column-drag-handle {
            transform: rotate(90deg);
        }

        .ag-theme-alpine-dark .ag-paging-row-summary-panel-number {
            @include mat.m2-typography-level($typography, body-1);
        }

        .ag-paging-button .ag-icon {
            font-size: mat.m2-font-size($typography, subtitle-1);
        }

        .ag-menu {
            border: none;
            background-color: colors.$bg-surface-3;
            @include mat.m2-typography-level($typography, body-1);
        }

        .ag-tab .ag-icon {
            color: mat.m2-get-color-from-palette($primary, 500);
        }

        .ag-tab.ag-tab-selected .ag-icon {
            color: mat.m2-get-color-from-palette($accent, 500);
        }

        .ag-menu-header {
            background-color: colors.$bg-surface-3;
            border-color: colors.$bg-surface-5;
        }

        .ag-menu-separator-part::after {
            border-color: colors.$bg-surface-5;
        }

        .ag-menu-option-active {
            background-color: colors.$row-hover;
        }

        .ag-header-cell.ag-header-cell-moving {
            background-color: colors.$bg-surface-5;
        }

        .ag-header-row.ag-header-row-column .multiple-rows-header-class {
            white-space: normal !important;
            text-align: center;
        }

        .ag-checkbox-input-wrapper {
            background: none;
        }
    }

    .ag-theme-alpine-dark.ag-dnd-ghost {
        background-color: colors.$bg-surface-4;
        border: none;

        .ag-dnd-ghost-label {
            @include mat.m2-typography-level($typography, body-1);
        }
    }

    .bottom-data .ag-center-cols-viewport {
        @include flex-layout($direction: column-reverse);
    }

    .no-data-selected-template {
        padding: 0 1rem;
    }

    .ag-sticky-bottom {
        border-top: transparent;
    }
}
