// Import custom component styling.
@import './components/button.theme.scss';
@import './components/card.theme.scss';
@import './components/chartiq-marker.theme.scss';
@import './components/checkbox.theme.scss';
@import './components/chips.theme.scss';
@import './components/datepicker.theme.scss';
@import './components/dialog.theme.scss';
@import './components/drawer.theme.scss';
@import './components/expansion-panel.theme.scss';
@import './components/form-field.theme.scss';
@import './components/input.theme.scss';
@import './components/list.theme.scss';
@import './components/menu.theme.scss';
@import './components/paginator.theme.scss';
@import './components/progress-bar.theme.scss';
@import './components/select-menu.theme.scss';
@import './components/sidenav-theme.scss';
@import './components/slide-toggle.theme.scss';
@import './components/snackbar.theme.scss';
@import './components/tab.theme.scss';
@import './components/table.theme.scss';
@import './components/toggle-button.theme.scss';
@import './components/tooltip.theme.scss';
@import './components/tippy-tooltip.theme.scss';

/**
$theme - a material theme map
$config - typography
*/
@mixin custom-component-theme($theme, $typography) {
    @include button-theme($theme);
    @include card-theme($theme, $typography);
    @include chartiq-marker-component-theme($theme, $typography);
    @include checkbox-theme($theme, $typography);
    @include chips-theme($theme, $typography);
    @include datepicker-theme($theme, $typography);
    @include dialog-theme($theme, $typography);
    @include drawer-theme();
    @include expansion-panel-theme($theme, $typography);
    @include form-field-theme($theme, $typography);
    @include input-theme($theme);
    @include list-theme($theme, $typography);
    @include menu-theme($theme, $typography);
    @include paginator-theme($theme);
    @include progress-bar-theme();
    @include select-menu-theme($theme, $typography);
    @include sidenav-theme();
    @include slide-toggle-theme();
    @include snackbar-theme($theme, $typography);
    @include tab-component-theme($theme);
    @include table-theme($theme, $typography);
    @include toggle-button-theme($theme);
    @include tooltip-theme($theme, $typography);
    @include tippy-tooltip-theme($theme, $typography);
}
