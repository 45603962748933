@use '@angular/material' as mat;
@import 'src/theme/components/scrollbar.theme';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin alert-description-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .trds-alert-description {
        .footer {
            border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
            border-top: 1px solid mat.m2-get-color-from-palette($foreground, divider);
        }

        .dialog-wrapper .footer {
            border-bottom-color: transparent;
        }

        @media (max-width: calc(#{$window-s} - 1px)) {
            .dialog-wrapper h2 {
                font-size: mat.m2-font-size($typography, subtitle-1);
                line-height: 1.2;
            }
        }
    }
}
