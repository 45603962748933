@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import '../alert-details/alert-details.mixins.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin alert-details-manual-theme($theme, $typography) {
    $accent: map-get($theme, accent);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .trds-alert-details-manual {
        @include alert-details-generic($foreground, $background, $typography, $theme);

        .attention {
            background-color: rgba(colors.$bg-surface-2, 0.85);
            color: mat.m2-get-color-from-palette($accent, 100);
            border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
        }

        .row.two-lines-row .key:nth-child(7):before {
            border-top: 1px solid mat.m2-get-color-from-palette($foreground, divider);
        }
    }
}
