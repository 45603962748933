@use '@angular/material' as mat;
@import 'src/theme/mixins/mixins.scss';

@mixin trds-grid-theme($theme, $typography) {
    $foreground: map-get($theme, foreground);

    .trds-grid {
        .row {
            border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
        }

        .key,
        .key-with-icon {
            color: mat.m2-get-color-from-palette($foreground, secondary-text);
        }

        &.no-border-grid .row {
            border-bottom-color: transparent;
            align-items: center;
        }
    }
}
