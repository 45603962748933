@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin priority-overrides-list-theme($theme) {
    $foreground: map-get($theme, foreground);

    .trds-priority-overrides-list {
        .empty-table-message,
        .default-message-content {
            color: colors.$low-priority;
        }
    }
}
