@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';
@import 'src/theme/mixins/mixins.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin dialog-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .cdk-overlay-backdrop:not(.cdk-overlay-transparent-backdrop) {
        background-color: colors.$bg-surface-1;
        opacity: 0.75;

        &.blurred-backdrop {
            backdrop-filter: blur($double-gap);
            -webkit-backdrop-filter: blur($double-gap);
            opacity: initial;
            background-color: initial;
        }
    }

    mat-dialog-container {
        @include mat.elevation(0);
        background: transparent;
        padding: 0;

        .dialog-wrapper {
            background-color: colors.$bg-surface-3;
            padding: $double-gap;
            border-radius: $radius-sm;
            @include mat.elevation(12);

            h2 {
                margin-top: 0;
            }

            // The wrapper around title and icon or close button
            .dialog-icon-header {
                @include flex-layout(center, space-between);

                h2 {
                    margin: 0;
                }
            }

            [mat-dialog-title],
            [mat-dialog-actions] {
                padding-left: 0;
                padding-right: 0;
                
                &::before {
                    display: none;
                }
            }

            [mat-dialog-content] {
                padding-left: $double-gap;
                padding-right: $double-gap;
                margin: 0 (-$double-gap);
            }
        }

        .trds-dialog {
            display: block;
            max-height: inherit;
            overflow: hidden;
        }

        @media (max-width: calc(#{$window-s - 1px})) {
            .dialog-wrapper {
                @include flex-layout($direction: column);
                max-height: inherit;
                box-sizing: border-box;
                width: 100%;

                mat-dialog-content {
                    max-height: unset;
                }

                h2 {
                    margin-bottom: $three-quarter-gaps;
                }
            }
        }

        @media (max-width: calc(#{$window-xxs - 1px})) {
            h2 {
                font-size: mat.m2-font-size($typography, body-1);
                line-height: 1.2;
            }

            .close-btn {
                @include small-btn-styles();
                line-height: $small-icon-size;
            }

            .dialog-icon-header {
                margin-bottom: $half-gap;
            }

            [mat-dialog-content] {
                flex: 1;
            }

            .dialog-wrapper {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                padding: $gap $small-dialog-side-padding;
                border-radius: 0;
                height: 100vh;
                max-height: unset;

                mat-form-field {
                    font-size: mat.m2-font-size($typography, body-2);
                }
            }
        }
    }
}
