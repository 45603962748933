@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables';

// Define a custom mixin that takes in the current theme
@mixin snackbar-theme($theme, $typography) {
    // Parse the theme and create variables for each color in the pallet
    $warn: map-get($theme, warn);

    mat-snack-bar-container.mdc-snackbar {
        .snackbar-container {
            @include mat.m2-typography-level($typography, body-1);
            color: colors.$light-highlight-text;
            line-height: 1.5;
        }

        // needs save depth level of 2 because of requirements of product
        .mdc-snackbar__surface { 
            background: transparent;
        }

        &.warn-background {
            margin: $gap 0 $triple-gap;
            max-width: 100%;
            background: mat.m2-get-color-from-palette($warn, A400);
    
            button {
                border: transparent;
                background: mat.m2-get-color-from-palette($warn, A400);
            }
        }

        &.success-background {
            background: colors.$score-low;

            button {
                border: transparent;
                background: colors.$score-low;
            }
        }

        &.info-background {
            background: colors.$snackbar-info-bg;

            button {
                border: transparent;
                background: colors.$snackbar-info-bg;
            }
        }

        @media (max-width: calc(#{$window-s - 1px})) {
            min-width: 100% !important;
            margin: 0 !important;


            .mdc-snackbar__surface {
                background: transparent;
                max-width: 100%;
                width: 100%;
            }
        }
    }
}
