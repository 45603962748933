@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin ip-geolocation-check-outputs-dialog-theme($theme) {
    $foreground: map-get($theme, foreground);

    // Define any styles affected by the theme.
    .trds-ip-geolocation-check-outputs-dialog p{
        color: mat.m2-get-color-from-palette($foreground, secondary-text);
    }
}