@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin case-escalation-event-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .trds-case-event {
        .event-title {
            color: mat.m2-get-color-from-palette($foreground, secondary-text);
        }

        .footer {
            color: colors.$disabled-color;
        }
    }
}
