@use '@angular/material' as mat;
@import 'src/theme/components/scrollbar.theme';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin alert-report-theme($theme, $typography) {
    $foreground: map-get($theme, foreground);

    .trds-alert-report {

        .panel-title {
            color: white;
        }

        .sub-panel-title, .expansion-sub-panel-title {
            @include mat.m2-typography-level($typography, body-2);
            color: rgba(255, 255, 255, 0.7);
        }

         .panel-title-actions-title {
            color: rgba(255, 255, 255, 0.7);
        }

        .action-row {
            border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
            border-top: 1px solid mat.m2-get-color-from-palette($foreground, divider);
        }
    }
}
