@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin algo-details-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);

    .trds-algo-details {
        .details-container {
            border-top: 1px solid mat.m2-get-color-from-palette($foreground, divider);
            border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
            background-color: colors.$app-background;
        }

        .algo-details {
            .description {
                color: mat.m2-get-color-from-palette($foreground, secondary-text);
            }

            .legend-item {
                @include mat.m2-typography-level($typography, body-2);
            }
        }

        .box {
            &.invalid {
                background-color: rgba(colors.$invalid-param, 0.3);
            }

            &.changed {
                background-color: rgba(colors.$modified-param, 0.3);
            }
        }

        .no-name {
            color: colors.$disabled-color;
        }

        .no-algo {
            color: colors.$low-priority;
        }
    }
}
