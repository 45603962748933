@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin model-test-analytics-chart-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);

    .trds-model-test-analytics-chart-theme {
        .container {
            background-color: colors.$bg-surface-2;
            border: 1px solid colors.$app-hyperlink;
        }

        .title {
            color: #EEF0F4;
        }
    }
}
