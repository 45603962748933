@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin alert-str-audit-trail-theme($theme) {
    $foreground: map-get($theme, foreground);

    .key-name {
        color: mat.m2-get-color-from-palette($foreground, secondary-text);
    }
}
