@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

@mixin alert-participants-identifiers-theme() {
    .trds-alert-participants-identifiers {
        .trds-label,
        .trds-label:hover {
            background-color: colors.$bg-surface-3;
            color: mat.m2-get-color-from-palette(colors.$solidus-primary, 100);
        }

        .trds-label {
            border-radius: $gap;
            border: none;
            padding: $quarter-gap calc($gap + $quarter-gap);
        }
    }
}
