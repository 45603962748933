@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin events-description-theme($theme, $typography) {
    $foreground: map-get($theme, foreground);

    trds-events-description {
        .side-label,
        .event-type-title,
        .side-title,
        .events-title{
            @include mat.m2-typography-level($typography, body-2);
            line-height: 1.4rem;
        }

        mat-icon.event-type-icon svg {
            width: 16px;
            height: 16px;
        }

        .delimiter {
            background: colors.$bg-surface-3;
        }
    }

    .more-filter-item {
        color: mat.m2-get-color-from-palette($foreground, secondary-text);
        @include flex-layout(center);
        padding: 0 10px;
        height: 3rem;
        min-width: 10rem;

        .event-status-line-img {
            background: mat.m2-get-color-from-palette($foreground, secondary-text);
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }
}
