@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@use 'src/theme/solidus-black/typography' as typography;

@mixin data-audit-stats-theme($theme, $typography) {
    $foreground: map-get($theme, foreground);

    .trds-data-audit-stats {
        .container {
            border: 1px solid colors.$app-hyperlink;
            background-color: colors.$bg-surface-2;

            .vertical-line {
                border-color: colors.$app-hyperlink;
            }

            .statistics {

                .valid,
                .duplicates,
                .errors {
                    border-left: 1px solid colors.$bg-surface-3;

                    h3 {
                        color: mat.m2-get-color-from-palette($foreground, secondary-text);
                    }

                    .value {
                        font-size: 1.71rem;
                    }
                }

                .valid .value {
                    color: #1DCA77;
                }

                .duplicates .value {
                    color: #FF8C40;
                }

                .errors .value {
                    color: #F24D3D;
                }

                .total {
                    h2 {
                        color: mat.m2-get-color-from-palette(colors.$solidus-accent, 300);
                    }

                    .value {
                        font-size: 2.29rem;
                        line-height: 1;
                    }
                }
            }

            .chart {
                .export-btn {
                    border: 1px solid colors.$app-hyperlink;
                }
            }
        }
        
        @media (max-width: calc(#{$window-xs} - 1px)) {
            .container {
                .statistics {
                    .total {
                        h2 {
                           @include mat.m2-typography-level($typography, subtitle-1);
                        }
    
                        .value {
                            font-size: 1.75rem;
                            line-height: 1;
                        }
                    }
    
                    .valid,
                    .duplicates,
                    .errors {
                        h3 {
                            @include mat.m2-typography-level($typography, subtitle-2);
                        }
    
                        .value {
                            font-size: 1.25rem;
                        }
                    }
                }
            }
        }
    }
}
