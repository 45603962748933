@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin ms-chart-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    // Define any styles affected by the theme.
    .trds-ms-chart {
        .chart-overlay:hover {
            background-color: rgba(255, 255, 255, 0.2);

            p {
                color: mat.m2-get-color-from-palette($accent);
            }
        }

        .stx_crosshair,
        .body,
        .head {
            background-color: mat.m2-get-color-from-palette($accent);
        }

        .stx_xaxis,
        .stx_yaxis {
            @include mat.m2-typography-level($tardis-typography, caption);
            color: mat.m2-get-color-from-palette($foreground, secondary-text);
        }

        .stx-panel-title {
            @include mat.m2-typography-level($tardis-typography, caption);
            color: mat.m2-get-color-from-palette($foreground, secondary-text);
            text-transform: uppercase;
            box-shadow: none;
            margin-top: $half-gap;
        }

        .marker_content {
            h4 {
                letter-spacing: 0.14px;
                margin-bottom: $half-gap;

                &.link {
                    color: colors.$app-hyperlink;
                }

                &:empty {
                    margin-bottom: 0;
                }
            }
        }

        .stx_jump_today.home {
            // disable button that appears on right side of chart. !important
            // necessary because this button's styles are set dynamically by adding
            // a style tag in the dom
            display: none !important;
        }

        .stx_volume_up {
            border-left-color: transparent;
        }
        .stx_volume_down {
            border-left-color: transparent;
        }

        // disable button that appears next to symbol on chart hover
        html:not([ciq-last-interaction='touch']) .stx-holder:hover .stx-btn-panel {
            display: none;
        }

        .stx-ico-focus {
            display: none;
        }
    }

    .stx-marker.execution .stx-visual {
        background-color: mat.m2-get-color-from-palette($accent);
        width: 13px;
        height: 13px;
    }

    .stx-marker.needle .stx-visual {
        background-color: mat.m2-get-color-from-palette($accent);
        width: 0.5rem;
        height: 0.5rem;
    }

    .stx-marker.buy .stx-visual {
        background: white;
        width: 13px;
        height: 13px;
    }

    .stx-marker.sell .stx-visual {
        background: white;
        width: 13px;
        height: 13px;
    }

    .stx-performance-marker.stx-marker-expand {
        line-height: 1.5rem;
        max-width: 20.6rem;
        overflow: auto;
        border-radius: $radius-sm;
        padding: $three-quarter-gaps;
        text-align: left;
        z-index: 40;
        @include trds-tooltip();
        @include mat.elevation(4);
        border: 1px solid colors.$tooltip-bd-color;
        color:  mat.m2-get-color-from-palette($foreground, text);
        background-color: colors.$bg-surface-2;

        &::-webkit-scrollbar-thumb {
            background-color: rgba(colors.$bg-surface-2, 0.3);
        }

        consolidated p {
            border-top: 1px solid mat.m2-get-color-from-palette($foreground, divider);
            padding-top: $gap;
        }
    }

    .stx-stem {
        display: none;
    }

    html:not([ciq-last-interaction='touch']) .stx-marker.circle .stx-visual:hover:after {
        border: none;
    }

    .stx-marker .circle .needle {
        cursor: auto;
    }

    .stx-marker.news {
        .stx-visual {
            background: mat.m2-get-color-from-palette($accent, 100);
            width: var(--simple-marker-size);
            height: var(--simple-marker-size);
        }

        p {
            display: block;
        }
    }
    
    .stx_grid {
        color: mat.m2-get-color-from-palette($foreground, divider);
        border-style: solid;
    }
}
