// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin ag-grid-mi-alerts-table-theme($theme, $typography) {
    $background: map-get($theme, background);

    .ag-grid-mi-alerts-table {
        .ag-theme-alpine-dark .ag-sort-order {
            display: none;
        }

        .ag-side-bar-right .ag-tool-panel-wrapper {
            background-color: colors.$bg-surface-3;
            border-right: none;
            width: 175px;
        }

        .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected) {
            border: none;
        }
    }

    .ag-theme-alpine-dark {
        --ag-grid-size: 5.5px; /* very compact */
        --ag-header-height: 32px;
        --ag-cell-horizontal-padding: 7px;
    }
}
