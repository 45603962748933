@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin priority-theme($typography) {
    .trds-priority {
        display: flex;

        .high {
            color: colors.$high-priority;
        }

        .medium {
            color: colors.$medium-priority;
        }

        .low {
            color: colors.$low-priority;
        }

        trds-mat-icon .mat-icon {
            color: inherit;
        }

        .trds-label {
            text-transform: capitalize;
            @include mat.m2-typography-level($typography, body-1);
        }
    }
}
