@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin crypto-icon-theme($theme, $typography) {
    .trds-crypto-icon {
        .general-symbol {
            background-color: colors.$low-priority;

            span {
                @include mat.m2-typography-level($typography, subtitle-2);
            }
        }
    }
}
