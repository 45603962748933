@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin authentication-theme($theme) {
    $background: map-get($theme, background);

    .trds-authentication {
        .login-container {
            background-color: colors.$bg-surface-3;
        }
    }
}
