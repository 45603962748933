@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin symbol-exchange-filter-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);

    .bottom-line {
        border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
    }

    .top-line {
        border-top: 1px solid mat.m2-get-color-from-palette($foreground, divider);
    }

     trds-symbol-exchange-filter .filter-input {
        .xs-size &{
            width: 13.7rem;
        }

        .extras-size &{
            width: calc(50% - 0.8rem);
        }

    }

    .s-size trds-symbol-exchange-filter form {
        column-gap: $gap;
    }
    
}
