@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin time-in-status-theme($theme, $typography) {
    $foreground: map-get($theme, foreground);

    trds-time-in-status {
        .markers {
            @include mat.m2-typography-level($typography, body-2);
            color: mat.m2-get-color-from-palette($foreground, secondary-text);
        }

        .on-schedule {
            background-color: colors.$score-low;
        }

        .behind-schedule {
            background-color: colors.$score-medium;
        }

        .off-schedule {
            background-color: colors.$score-high;
        }
    
    }
}
