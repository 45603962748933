@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin playback-controller-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.

    $foreground: map-get($theme, foreground);

    // Define any styles affected by the theme.

    .trds-playback-controller {
        .play-container {
            background: colors.$bg-surface-2;
        }

        .action {
            font-family: mat.m2-font-family($typography, body-2);

            .prefix {
                font-family: mat.m2-font-family($typography, caption);
                color: mat.m2-get-color-from-palette($foreground, secondary-text);
            }
        }
    }
}
