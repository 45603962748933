@use 'sass:math';
@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import '../mixins/mixins.scss';
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin form-field-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);

    mat-form-field {
        margin-top: $one-third-gap;

        &.mat-form-field-appearance-fill {
            .mdc-text-field--filled:not(.mdc-text-field--disabled),
            .mdc-text-field--disabled.mdc-text-field--filled,
            .mat-mdc-form-field-focus-overlay {
                background: none;
            }

            .mdc-text-field  {
                padding: 0;
            }

            .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
                color: mat.m2-get-color-from-palette($foreground, secondary-text);
            }
            
        }

        &.mat-form-field-disabled {
            color: colors.$disabled-color;
    
            mat-form-field-label,
            .mat-mdc-select-value-text,
            button {
                color: colors.$disabled-color;
            }
        }

        [mat-icon-button].trds-suffix-button {
            @include small-btn-styles();
        }


        .mat-mdc-form-field-infix {
            min-height: 3.25rem;
            padding-bottom: $one-third-gap;
        }

        .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix,
        .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
            padding-top: 18px;
            padding-bottom: $one-third-gap;
        }

        .mdc-text-field--filled .mdc-floating-label--float-above {
            transform: translateY(-150%) scale(0.75);
        }

        .mat-mdc-form-field-hint-wrapper,
        .mat-mdc-form-field-error-wrapper {
            padding: 0;
        }

        .mat-mdc-form-field-hint-spacer {
            flex: 1;
        }

        .mat-mdc-form-field-icon-suffix,
        .mat-mdc-form-field-icon-prefix {
            align-self: baseline;
        }

        mat-datepicker-toggle[matsuffix] button svg {
            font-size: $small-icon-font;
            line-height: $small-icon-size;
            width: $small-icon-size;
            height: $small-icon-size;
        }
    }

    .mdc-form-field {
        @include mat.m2-typography-level($typography, body-1);
    }

    mat-hint,
    mat-error {
        font-size: 0.75rem;
        line-height: 1.3rem;
    }

    .mat-mdc-select-value,
    .mdc-text-field.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
        color: mat.m2-get-color-from-palette($foreground, text);
    }
}
