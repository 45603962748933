@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin drawer-theme() {
    // Extract the palettes you need from the theme definition.
    // needs save depth level of 2 because of requirements of product
    mat-sidenav.mat-drawer,
    mat-drawer.mat-drawer {
        background: colors.$bg-surface-2;
        @include mat.elevation(4); // override material box-shadow
    }
}
