@import 'src/theme/variables.scss';

mat-row,
[mat-row] {
    .action-buttons {
        @include flex-layout($halign: flex-end);
        width: 100%;
        overflow: hidden;

        .action-button-container {
            @include flex-layout(center);

            mat-spinner {
                margin: auto;
            }
        }

        button {
            margin: -100vh 0.2rem auto;

            &:focus-visible {
                margin-top: auto;
            }
        }
    }

    &:hover .action-buttons button {
        margin-top: auto;
    }
}
