@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/mixins/mixins';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin phone-number-control-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.

    trds-phone-number-control .messages {
        @include mat.m2-typography-level($typography, body-2);
        font-size: $error-font-size;
        margin-top: -(mat.m2-line-height($typography, body-2));
    }

    .select-menu-overlay-pane {
        width: 22rem !important;
    }
}
