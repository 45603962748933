@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin new-model-run-page-theme() {
    .trds-new-model-run-page {
        .description-card {
            border: 1px solid colors.$app-hyperlink;
            background: colors.$bg-surface-2;
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        }
    }
}
