@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin market-inspection-chart-description-button-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);

    $disabled-border-color: mat.m2-get-color-from-palette($primary, 700);
    $off-border-color: transparent;
    $selected-border-color: mat.m2-get-color-from-palette($accent, 500);

    .market-inspection-chart-description-button {
        .side-circle {
            &.buy {
                background-color: colors.$side-buy;
            }

            &.sell {
                background-color: colors.$side-sell;
            }

            &.alert {
                background-color: mat.m2-get-color-from-palette($accent, 100) !important;
            }

            &:hover {
                cursor: pointer;
            }
        }

        .alert-side-circle {
            &.alert {
                background-color: mat.m2-get-color-from-palette($accent, 100) !important;
            }
        }

        .event-type-icon {
            .mat-icon:disabled {
                color: $selected-border-color;
            }
        }

        .disabled {
            opacity: 0.7;
        }
    }

    .horizontal-center {
        .alert-button {
            border-color: mat.m2-get-color-from-palette(colors.$solidus-accent, 500) !important;
        }

        .active-btn {
            background-color: rgba(255, 205, 38, 0.15);
        }
    }

    .button-enabled {
        border: 1px solid mat.m2-get-color-from-palette(colors.$solidus-accent, 500);

        &:hover {
            cursor: pointer;
            color: mat.m2-get-contrast-color-from-palette($accent, 100);
            background-color: mat.m2-get-color-from-palette(colors.$solidus-accent, 100);

            .event-type-icon {
                color: mat.m2-get-contrast-color-from-palette($accent, 100);
            }
        }
    }

    .alert-related:not(:hover) {
        background-color: rgba(255, 205, 38, 0.15) !important;
    }

    .button-disabled {
        border: 1px solid $off-border-color;
        pointer-events: none;
        color: rgba(255, 255, 255, 0.5);

        &.button-on {
            border: 1px solid $disabled-border-color;
            background-color: rgba(255, 255, 255, 0.15);

            &.light-filter {
                background-color: $disabled-border-color;
                mat-icon {
                    color: mat.m2-get-contrast-color-from-palette($accent, 100);
                }
            }
        }
    }

    .button-on.light-filter {
        background-color: mat.m2-get-color-from-palette(colors.$solidus-accent, 300);
        color: mat.m2-get-contrast-color-from-palette($accent, 100);
    }

    .button-off {
        border: 1px solid $off-border-color;
    }

    .delimiter {
        background: colors.$bg-surface-3;
    }

    .button-container {
        background-color: colors.$bg-surface-3;
    }
}
