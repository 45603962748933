@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin model-testing-no-rows-overlay-theme($theme, $typography) {
    .trds-model-testing-no-rows-overlay {

        .title {
            color: colors.$snackbar-info-bg;
        }

        .list-item {
            .mark {
                background: colors.$snackbar-info-bg;
                color: colors.$app-background;
                line-height: mat.m2-line-height($typography, body-1);
            }
        }
    }
}
