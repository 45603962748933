@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin orderbook-theme($theme, $tardis-typography) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);

    .trds-orderbook {
        .orderbook-container {
            background: colors.$bg-surface-2;
        }

        .table-wrapper:not(:first-child) {
            border-top: 1px solid mat.m2-get-color-from-palette($foreground, divider);
        }

        .ask {
            color: colors.$score-low;
        }

        .bid {
            color: colors.$score-high;
        }
    }
}
