@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin compare-matches-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .trds-compare-matches-dialog .match-details-container {
        background-color: colors.$bg-surface-2;
    }

    @media (max-width: calc(#{$window-m - 1px})) {
        .trds-compare-matches-dialog {
            .check-titles h2 {
                font-size: mat.m2-font-size($typography, body-1);
            }

            .paginator button {
                @include small-btn-styles();
                line-height: $small-icon-size;
            }
        }
    }

    @media (max-width: calc(#{$window-s - 1px})) {
        .trds-compare-matches-dialog {
            font-size: mat.m2-font-size($typography, body-2);

            .check-titles h2 {
                font-size: mat.m2-font-size($typography, body-2);
            }
        }
    }
}
