@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin team-history-theme($theme) {
    $foreground: map-get($theme, foreground);

    .trds-team-history {
        background: colors.$app-background;
    }
}
