@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin client-data-view-panel-theme($theme, $typography) {
    $foreground: map-get($theme, foreground);

    trds-client-data-view-panel{
        .investigation-wrapper{
            border-bottom: 2px solid mat.m2-get-color-from-palette($foreground, divider);
        }

        .trds-client-data-view-panel-theme {
            border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);

            &:not(:has(.item)) {
                border-bottom: none;
            }

            .item {
                border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
            }
    
            .title {
                color: mat.m2-get-color-from-palette($foreground, secondary-text);
            }
    
            .left-gap {
                margin-left: $gap;
            }
    
            .value-clickable:hover {
                cursor: pointer;
                color: mat.m2-get-color-from-palette(colors.$solidus-accent, 300);
            }
    
            .missing-values-card {
                background: colors.$bg-surface-2;
    
                .icon {
                    color: mat.m2-get-color-from-palette(colors.$solidus-primary, 500);
                }
    
                .link {
                    color: mat.m2-get-color-from-palette(colors.$solidus-accent, 500);
                    cursor: pointer;
    
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
