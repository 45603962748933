@use 'src/theme/solidus-black/colors' as colors;
@use '@angular/material' as mat;


@mixin case-time-in-statuses($theme) {
    $accent: map-get($theme, accent);

    .trds-case-time-in-statuses {
        .status-time:hover {
            color: mat.m2-get-color-from-palette($accent, 100);
        }

        .status-color {
            &-onSchedule {
                color: colors.$score-low;
            }

            &-behindSchedule {
                color: colors.$score-medium;
            }

            &-offSchedule {
                color: colors.$score-high;
            }
        }
    }
}
