@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin slide-toggle-theme() {
    // Extract the palettes you need from the theme definition.

    mat-slide-toggle svg {
        display: none;
    }
}
