@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;

@mixin export-case-dialog-theme($theme) {
    $warn: map-get($theme, warn);

    .trds-export-case-dialog {
        .info-message-block {
            background: colors.$low-priority;
            color: colors.$app-background;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 0.25rem;
            padding: $gap;
        }
    }
}
